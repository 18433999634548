<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>添加银行卡</h6>
            </div>
            <div class="col-12 mb-4">
              <div class="text-gray-777 mt-3">
                请填写银行卡及身份信息，充值和提现会对您信息进行验证
              </div>
              <div
                v-if="tips != ''"
                class="alert alert-danger p-1 mt-2 d-flex align-items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-lightbulb"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"
                  />
                </svg>
                <span class="ml-1 mt-1">{{ tips }}</span>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>持卡者</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="cardHolder"
                    class="form-control mr-sm-2 width-300"
                    placeholder="请输入您的真实姓名"
                    maxlength="10"
                    type="text"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>身份证号码</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="idNumber"
                    class="form-control mr-sm-2 width-300"
                    placeholder="请输入身份证号码"
                    maxlength="18"
                    type="text"
                  />
                </div>
              </div>
              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>开户银行</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="openBank"
                    class="form-control mr-sm-2 width-300"
                    maxlength="30"
                    placeholder="如：农业银行-某某支行"
                    type="text"
                  />
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>银行卡号</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="cardNumber"
                    class="form-control mr-sm-2 width-300"
                    maxlength="30"
                    onkeyup="this.value=this.value.replace(/\D/g,'').replace(/....(?!$)/g,'$&amp; ')"
                    placeholder="请输入您要绑定的银行储蓄卡卡号"
                    type="text"
                  />
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>预留手机号</span>
                </div>
                <div class="float-left">
                  <input
                    v-model="mobile"
                    class="form-control mr-sm-2 width-300"
                    maxlength="11"
                    placeholder="请输入您预留的手机号"
                    type="text"
                  />
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>短信验证码</span>
                </div>
                <div class="float-left">
                  <div class="clearfix">
                    <div class="float-left">
                      <b-form-input
                        type="text"
                        class="rounded width-182"
                        v-model="code"
                        placeholder="填写动态码"
                        maxlength="4"
                        autocomplete="off"
                      ></b-form-input>
                    </div>
                    <div class="float-right ml-2">
                       <b-button class="width-110" @click="getCode"
                        :variant="seconds == 0 ? 'success' : 'secondary'"
                        :disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix mt-3">
                <b-button class="width-300"
                  @click="addCard"
                  variant="success"
                  style="margin-left: 116px"
                  >添加银行卡</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "bankcardadd",
  data() {
    return {
      tips: "", //消息提示
      cardHolder: "", //持卡者
      cardNumber: "", //银行卡号
      idNumber: "", //身份证号码
      mobile: "", //预留电话
      openBank: "", //开户银行
      code: "", //验证码
      seconds: 0,
      timer: null,
    };
  },
  methods: {
    verify() {
      if (this.cardHolder.trim() == "") {
        this.tips = "请填写持卡者姓名";
        return false;
      }
      if (this.idNumber.trim() == "") {
        this.tips = "请填写身份证号";
        return false;
      }
      if (!this.$test.idCard(this.idNumber)) {
        this.tips = "请填写正确的身份证号";
        return false;
      }
      if (this.openBank.trim() == "") {
        this.tips = "请填写开户银行";
        return false;
      }
      if (this.cardNumber.trim() == "") {
        this.tips = "请填写银行卡号";
        return false;
      }
      if(!this.verifyMobile()) {
        return false;
      }
      if (this.code == "") {
        this.tips = "请填写短信验证码";
        return false;
      }
      return true;
    },
    verifyMobile() {
      if (this.mobile == '') {
        this.$model.toast('请填写预留手机号码',2000)
        return false
      } else {
        if (!this.$test.mobile(this.mobile)) {
          this.$model.toast('请填写正确的预留手机号码',2000)
          return false
        }
      }
      return true;
    },
    getCode() {
      if (this.verifyMobile()) {
        this.seconds = 60;
        this.timer = setInterval(() => {
          // 执行加或减函数
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    addCard() {
      if (this.verify()) {
        this.$http
          .post(this.$api.user.addBankcard, {
            cardHolder: this.cardHolder,
            cardNumber: this.cardNumber,
            idNumber: this.idNumber,
            mobile: this.mobile,
            openBank: this.openBank,
            code: this.code,
          })
          .then((res) => {
            if (res.code == 1) {
              Object.assign(this.$data, this.$options.data());
              this.$model.toast("银行卡添加成功",1500);
              setTimeout(() => {
                this.$router.push('/user/account/bankcard');
              }, 1500);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
